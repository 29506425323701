<template>
  <div>
      我是搜索页
      <h1>params参数---{{$route.params.keyword}}</h1>
      <h1>query参数---{{$route.query.k}}</h1>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>